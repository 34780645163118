import { Cost, Summary } from '../components/Results.types';

function getSummary(results: Cost[]): Summary {
  const [preSubsidyMin, preSubsidyMax] = results.reduce(
    (acc, result) => {
      const [min, max] = acc;
      return [min + result.cost.baseCost[0], max + result.cost.baseCost[1]];
    },
    [0, 0]
  );
  const [servicesMin, servicesMax] = results.reduce(
    (acc, result) => {
      const [min, max] = acc;
      return [
        min + result.cost.servicesCost[0],
        max + result.cost.servicesCost[1],
      ];
    },
    [0, 0]
  );
  const [subsidiesMin, subsidiesMax] = results.reduce(
    (acc, result) => {
      const [min, max] = acc;
      return [
        min + result.cost.sdlSubsidy[0] + result.cost.mafSubsidy[0],
        max + result.cost.sdlSubsidy[1] + result.cost.mafSubsidy[1],
      ];
    },
    [0, 0]
  );

  const mediShieldLife = Math.min(
    ...results.map((result) => result.cost.mslClaimPerMonth)
  );

  const mediSave = Math.min(
    ...results.map((result) => result.cost.msWithdrawPerMonth)
  );

  const papSub = results.reduce(
    (acc, result) => acc + result.cost.papSubPerMonth,
    0
  );

  const [mslCoPayMin, mslCoPayMax] = results.reduce(
    (acc, result) => {
      const [min, max] = acc;
      return [
        min + result.cost.mslCoPayPerMonth[0],
        max + result.cost.mslCoPayPerMonth[1],
      ];
    },
    [0, 0]
  );

  const [netPayableMin, netPayableMax] = [
    Math.max(preSubsidyMin - subsidiesMin + mediShieldLife, mslCoPayMin),
    Math.max(preSubsidyMax - subsidiesMax + mediShieldLife, mslCoPayMax),
  ];

  const [mediShieldLifeDisplayMin, mediShieldLifeDisplayMax] = [
    preSubsidyMin - subsidiesMin - netPayableMin,
    preSubsidyMax - subsidiesMax - netPayableMax,
  ];

  const [minMediSave, maxMediSave] = [
    Math.max(-netPayableMin, mediSave),
    Math.max(-netPayableMax, mediSave),
  ];

  const [minPapSub, maxPapSub] = [
    Math.max(-netPayableMin - minMediSave, papSub),
    Math.max(-netPayableMax - maxMediSave, papSub),
  ];

  const [subTotalMin, subTotalMax] = [
    netPayableMin + minMediSave + minPapSub,
    netPayableMax + maxMediSave + maxPapSub,
  ];

  const [totalMin, totalMax] = [
    subTotalMin + servicesMin,
    subTotalMax + servicesMax,
  ];

  return {
    preSubsidyMin,
    preSubsidyMax,
    servicesMin,
    servicesMax,
    subsidiesMin,
    subsidiesMax,
    mediShieldLife,
    mediSave,
    mslCoPayMin,
    mslCoPayMax,
    papSub,
    netPayableMin,
    netPayableMax,
    mediShieldLifeDisplayMin,
    mediShieldLifeDisplayMax,
    minMediSave,
    maxMediSave,
    minPapSub,
    maxPapSub,
    subTotalMin,
    subTotalMax,
    totalMin,
    totalMax,
  };
}

export default getSummary;
