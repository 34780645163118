import { Box, Flex, useAuth, Badge } from '@fivehealth/botero';
import { ReactNode, useEffect } from 'react';
import {
  Route as ReactRouterRoute,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { useAppData } from '../context/AppDataContext';
import PatientDetails from './patientDetails';
import Regimen from './Regimen';
import Results from './Results';

interface Props {
  children?: ReactNode;
  path?: string;
}

function CustomRoute({ children, path }: Props) {
  const {
    authState: { authenticated },
  } = useAuth();

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!authenticated) {
      history.push(`/login?hospital=${location.pathname.replace('/', '')}`);
    }
  }, [authenticated]);

  return (
    <ReactRouterRoute
      path={path}
      render={() => (authenticated ? children : null)}
    />
  );
}

function AuthCallback() {
  return null;
}

function CustomRoutes({ loggedIn }: { loggedIn: boolean }) {
  const { results } = useAppData();
  return (
    <Switch>
      <ReactRouterRoute exact path="/auth/callback">
        <AuthCallback />
      </ReactRouterRoute>
      <CustomRoute path="/">
        {loggedIn && (
          <Flex mt={24} flexDirection={['column', 'row']}>
            <Box
              flex={1}
              pb={5}
              mb={[5, 0]}
              borderBottom={['1px solid #D5D7DE', 'none']}
              mr={[0, '42px']}
            >
              <Regimen />
              <PatientDetails />
              <Box
                display={[results.length ? 'none' : 'flex', 'flex']}
                mt={4}
                width="calc(100% - 22px)"
              >
                <Badge
                  bg="lightestShade"
                  color="darkestShade"
                  borderRadius={8}
                  ml={0}
                  p={2}
                >
                  🐛 Facing issues or bugs with ChemoCalc? Let us know at{' '}
                  <a
                    style={{ color: '#256BF6', textDecoration: 'underline' }}
                    href="mailto:customersupport@botmd.io?subject=ChemoCalc v2"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    customersupport@botmd.io
                  </a>
                </Badge>
              </Box>
            </Box>
            <Box flex={2}>
              <Results />
            </Box>
          </Flex>
        )}
      </CustomRoute>
    </Switch>
  );
}

export default CustomRoutes;
