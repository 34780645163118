import {
  Box,
  FAIcon,
  Flex,
  H2,
  Input,
  PrimaryButton,
  SecondaryOutlinedButton,
  useApi,
  useAuth,
} from '@fivehealth/botero';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { isEqual, uniqWith } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import Config from '../Config';
import { GRAPHQL_DOCUMENT_CHEMOCALC_REPORT } from '../api/queries/useReport';
import { useAppData } from '../context/AppDataContext';
import getSummary from '../utils/resultsSummary';

function validateEmail(email: string) {
  const regexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(email);
}

function ExportModal({ closeModal }: { closeModal: () => void }) {
  const [outputFormat, setOutputFormat] = useState('excel');
  const [destination, setDestination] = useState('download');
  const [exporting, setExporting] = useState(false);
  const [email, setEmail] = useState({ value: '', error: '' });
  const { authState } = useAuth();
  const [cookies] = useCookies([Config.cookie.name]);
  const user = cookies && cookies[Config.cookie.name];

  const { client } = useApi();
  const { results, selectedRegimens } = useAppData();

  const getVerbosePCHI = () => {
    if (results[0].userInputs.phci === 0) {
      return '';
    }
    if (results[0].userInputs.phci <= 1200) {
      return '$0 < PCHI ≤ $1200';
    }
    if (results[0].userInputs.phci <= 2000) {
      return '$1200 < PCHI ≤ $2000';
    }
    if (results[0].userInputs.phci <= 3300) {
      return '$2000 < PCHI ≤ $3300';
    }
    if (results[0].userInputs.phci <= 6500) {
      return '$3300 < PCHI ≤ $6500';
    }
    if (results[0].userInputs.phci > 6500) {
      return 'PCHI > $6500';
    }
    return '';
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (destination === 'email' && email.value) {
        const emailError = !validateEmail(email.value);
        if (emailError) {
          setEmail({ ...email, error: 'Email is not valid' });
        }
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [destination, email.value]);

  const handleResultExport = () => {
    const summary = getSummary(results);

    const drugIndications = uniqWith(
      results.map((result) => result.userInputs.drugIndications).flat(1),
      isEqual
    ).map((drugIndication) => ({
      drug: drugIndication.drug,
      maf: drugIndication.maf,
      cdl: drugIndication.cdl,
    }));

    setExporting(true);

    client
      .request(GRAPHQL_DOCUMENT_CHEMOCALC_REPORT, {
        height: results[0].userInputs.height,
        weight: results[0].userInputs.weight,
        bsa: results[0].userInputs.bsa,
        phci: getVerbosePCHI(),
        residency: results[0].userInputs.residency,
        generation: results[0].userInputs.generation,
        subsidy: results[0].userInputs.subsidyStatus,
        choiceOfDrug: results[0].userInputs.brandStatus,
        drugIndications,
        basePrice: summary.preSubsidyMax,
        subsidies: summary.subsidiesMax,
        mslDeductions: summary.mediShieldLifeDisplayMax,
        netPayableDrugs: summary.netPayableMax,
        oopDrugs: summary.subTotalMax,
        otherDrugsServices: summary.servicesMax,
        msDeduction: Math.abs(summary.maxMediSave),
        totalOop: summary.totalMax,
        regimenCycles: selectedRegimens.map((data) => ({
          regimen: data.regimen.regimen,
          cycles: data.cycles,
        })),
        miniapp: `${user.hospital?.toLowerCase()}_chemocalc`,
        email: destination === 'email' ? email.value : null,
        convertToPdf: outputFormat === 'pdf',
      })
      .then((response: any) => {
        const { chernobylChemocalcReport } = response;
        const reportUrl = chernobylChemocalcReport.reportUrl.replace(
          // eslint-disable-next-line no-template-curly-in-string
          '${session}',
          authState.token
        );

        if (destination === 'download') {
          setTimeout(() => {
            window.open(reportUrl, '_blank');
          }, 1000);
        }
        setExporting(false);
        closeModal();
      });
  };

  return (
    <Box>
      <Flex mb={3} justifyContent="space-between" alignItems="center">
        <H2>Export Results</H2>
        <Box cursor="pointer" ml={6} onClick={closeModal}>
          <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
        </Box>
      </Flex>
      <Flex flexDirection="column">
        <FormControl>
          <FormLabel id="outputFormat">Select output format</FormLabel>
          <RadioGroup
            row
            aria-labelledby="outputFormat"
            value={outputFormat}
            name="outputFormat"
            onChange={(e) => {
              setOutputFormat(e.target.value);
            }}
          >
            <FormControlLabel
              sx={{
                fontWeight: 400,
                fontSize: '14px',
              }}
              value="pdf"
              control={<Radio />}
              label="PDF"
            />
            <FormControlLabel
              sx={{
                fontWeight: 400,
                fontSize: '14px',
              }}
              value="excel"
              control={<Radio />}
              label="Excel"
            />
          </RadioGroup>
        </FormControl>
        <FormControl>
          <FormLabel id="destination">Export destination</FormLabel>
          <RadioGroup
            aria-labelledby="destination"
            value={destination}
            name="destination"
            onChange={(e) => {
              setDestination(e.target.value);
            }}
          >
            <FormControlLabel
              value="download"
              control={<Radio />}
              label="Download to device"
            />
            <FormControlLabel
              value="email"
              control={<Radio />}
              label="Send to email"
            />
          </RadioGroup>
        </FormControl>
        {destination === 'email' && (
          <Input
            ml="2px"
            mr="2px"
            iconPosition="left"
            icon="email"
            type="email"
            state={email.error.length > 0 ? 'danger' : ''}
            errorLabel={email.error}
            className="input"
            size="medium"
            placeholder="Enter Email"
            label="Recipient email address"
            labelTextProps={{
              fontSize: '12px',
              fontWeight: 600,
            }}
            value={email.value}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setEmail({ value: e.currentTarget.value, error: '' })
            }
          />
        )}
      </Flex>
      <Flex mt={4}>
        <SecondaryOutlinedButton
          borderRadius="8px"
          fontWeight={500}
          onClick={closeModal}
        >
          Cancel
        </SecondaryOutlinedButton>
        <PrimaryButton
          borderRadius="8px"
          fontWeight={500}
          ml={3}
          onClick={handleResultExport}
        >
          {exporting ? 'Exporting...' : 'Export results'}
        </PrimaryButton>
      </Flex>
    </Box>
  );
}

export default ExportModal;
