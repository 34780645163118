import { createQuery } from '@fivehealth/botero';
import { gql } from 'graphql-request';

export const GRAPHQL_DOCUMENT_CHEMOCALC_REPORT = gql`
  query chernobylChemocalcReport(
    $miniapp: String!
    $height: Int
    $weight: Int
    $bsa: Float
    $phci: String!
    $residency: String!
    $generation: String!
    $subsidy: String!
    $drugIndications: [GenericScalar!]!
    $choiceOfDrug: String
    $basePrice: Float!
    $subsidies: Float!
    $mslDeductions: Float!
    $netPayableDrugs: Float!
    $oopDrugs: Float!
    $otherDrugsServices: Float!
    $msDeduction: Float!
    $totalOop: Float!
    $regimenCycles: [GenericScalar!]!
    $email: String
    $convertToPdf: Boolean
  ) {
    chernobylChemocalcReport(
      miniapp: $miniapp
      height: $height
      weight: $weight
      bsa: $bsa
      phci: $phci
      residencyStatus: $residency
      additionalSubsidies: $generation
      subsidyStatus: $subsidy
      drugIndications: $drugIndications
      choiceOfDrug: $choiceOfDrug
      basePrice: $basePrice
      subsidies: $subsidies
      mslDeductions: $mslDeductions
      netPayableDrugs: $netPayableDrugs
      oopDrugs: $oopDrugs
      otherDrugsServices: $otherDrugsServices
      msDeduction: $msDeduction
      totalOop: $totalOop
      regimenCycles: $regimenCycles
      email: $email
      convertToPdf: $convertToPdf
    ) {
      reportUrl
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT_CHEMOCALC_REPORT,
  queryType: 'query',
  baseQueryKey: 'chernobylChemocalcReport',
  keepPreviousData: true,
});
