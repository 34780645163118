import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Switch, useHistory } from 'react-router-dom';

import {
  ApiProvider,
  AuthProvider,
  Flex,
  ThemeProvider,
  useAuth,
} from '@fivehealth/botero';

import { GraphQLClient } from 'graphql-request';

import Content from './components/Content';
import Config from './Config';

import { GRAPHQL_DOCUMENT_USER_PROFILE } from './api/queries/useCurrentSession';
import './App.css';
import CustomRoutes from './components/CustomRoute';
import Login from './components/Login';
import Home from './components/Home';
import { AppDataProvider } from './context/AppDataContext';
import ModalProvider from './context/ModalContext';
import theme from './theme/theme';

const MUITheme = createTheme({
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
  },
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '14px',
          fontWeight: 400,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          fontWeight: 600,
        },
      },
    },
  },
});

interface QueryMap {
  [key: string]: any;
}

const apiQueryCtx = require.context('./api/queries', true, /.ts$/);
const queryMapping = apiQueryCtx
  .keys()
  .reduce((acc: QueryMap, path: string) => {
    const filename = path.split('./').pop();
    if (filename) {
      const key = filename.split('.ts');
      if (key.length) {
        return {
          ...acc,
          [key[0]]: apiQueryCtx(path).default,
        };
      }
    }
    return acc;
  }, {});

const onUnhandledError = () => <h3> There was an error. </h3>;

function AppContainer({
  hospital,
  loggedIn,
}: {
  hospital: string;
  loggedIn: boolean;
}) {
  return (
    <ModalProvider>
      <Flex minHeight="100vh">
        <Content hospital={hospital}>
          <CustomRoutes loggedIn={loggedIn} />
        </Content>
      </Flex>
    </ModalProvider>
  );
}

function AppRouter({
  onLogin,
  loggedIn,
}: {
  onLogin: (token: string, isMobileApp: boolean) => void;
  loggedIn: boolean;
}) {
  const { login, authState } = useAuth();
  const [cookies] = useCookies([Config.cookie.name]);
  const sessionData = cookies && cookies[Config.cookie.name];
  let sessionTokenFromCookie = null;
  if (
    (sessionData && typeof sessionData === 'string') ||
    sessionData instanceof String
  ) {
    sessionTokenFromCookie = sessionData;
  } else if (
    typeof sessionData === 'object' &&
    !Array.isArray(sessionData) &&
    sessionData !== null
  ) {
    sessionTokenFromCookie = sessionData.token;
  }

  const sessionTokenFromURL = new URLSearchParams(window.location.search).get(
    'x-session'
  );
  const sessionTokenFromAuthState = authState && authState.token;
  const sessionToken =
    sessionTokenFromURL || sessionTokenFromAuthState || sessionTokenFromCookie;

  useEffect(() => {
    // authState might take a few seconds to get set
    if (sessionToken && !authState.authenticated) {
      login({ token: sessionToken });
      onLogin(sessionToken, Boolean(sessionTokenFromURL));
    }
  }, [sessionToken]);

  return (
    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      {Config.ORGANIZATIONS.map((hospital: string) => (
        <Route key={hospital} path={`/${hospital.toLowerCase()}`}>
          <AppContainer hospital={hospital.toLowerCase()} loggedIn={loggedIn} />
        </Route>
      ))}
      {!authState.authenticated ? (
        <Route path="/">
          <Home />
        </Route>
      ) : null}
    </Switch>
  );
}

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const numOfHours = 3;
  const queryDefaultStaleTime = 1000 * 60 * 60 * numOfHours;

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnMount: false,
        refetchOnReconnect: false,
        staleTime: queryDefaultStaleTime,
      },
    },
  });

  const history = useHistory();

  /* eslint-disable-next-line */
  const [cookies, setCookie, removeCookie] = useCookies([Config.cookie.name]);
  const user = cookies && cookies[Config.cookie.name];

  const applicationInput = JSON.stringify({
    domain: 'auth.botmd.io',
  });

  const themeLogo = JSON.stringify({
    logo: `https://botmd-production-hippocrates-static.s3.amazonaws.com/hospital/${user?.hospital?.toUpperCase()}/${user?.hospital?.toUpperCase()}_transparent.png`,
  });

  const redirectUrl = `${window.location.origin}${Config.REDIRECT_PATH}`;

  const onLoginCallback = (token: string, isMobileApp = false) => {
    const apiClient = new GraphQLClient(Config.HIPPO_GQL_ENDPOINT, {
      headers: {
        'X-SESSION': token,
      },
    });

    apiClient
      .request(GRAPHQL_DOCUMENT_USER_PROFILE, {})
      .then(({ hospitalProfile }) => {
        removeCookie(Config.cookie.name, { path: '/' });
        if (token) {
          setCookie(
            Config.cookie.name,
            {
              token,
              firstName: hospitalProfile.firstName,
              lastName: hospitalProfile.lastName,
              hospital: hospitalProfile.hospital.shortName,
              isMobileApp,
            },
            { path: '/' }
          );
          setLoggedIn(true);
          history.push(`/${hospitalProfile.hospital.shortName.toLowerCase()}`);
        }
      })
      .catch(() => {
        removeCookie(Config.cookie.name, { path: '/' });
        history.push('/');
        window.location.reload();
      });
  };

  const onLogOutCallback = (hospital: string) => {
    removeCookie(Config.cookie.name, { path: `/${hospital}` });
    removeCookie(Config.cookie.name, { path: '/' });
    return history.push(`/login?hospital=${hospital}`);
  };

  const loginUrl = `${Config.LOGIN_URL}?uid=${Config.LOGIN_PROVIDER_UID}&redirectTo=${redirectUrl}&applicationInput=${applicationInput}&theme=${themeLogo}`;

  return (
    <MUIThemeProvider theme={MUITheme}>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route path="/">
            <AuthProvider
              loginUrl={loginUrl}
              redirectPath={Config.REDIRECT_PATH}
              onLogin={onLoginCallback}
              onLogout={() => onLogOutCallback(user.hospital.toLowerCase())}
              sessionQueryKey="session"
            >
              <CookiesProvider>
                <AppDataProvider>
                  <ApiProvider
                    queryMapping={queryMapping}
                    endpoint={Config.GQL_ENDPOINT}
                  >
                    <QueryClientProvider client={queryClient}>
                      <ErrorBoundary fallbackRender={onUnhandledError}>
                        <AppRouter
                          onLogin={onLoginCallback}
                          loggedIn={loggedIn}
                        />
                      </ErrorBoundary>
                    </QueryClientProvider>
                  </ApiProvider>
                </AppDataProvider>
              </CookiesProvider>
            </AuthProvider>
          </Route>
        </Switch>
      </ThemeProvider>
    </MUIThemeProvider>
  );
}

export default App;
