import { Dialog } from '@fivehealth/botero';
import React, { useMemo } from 'react';

const ModalContext = React.createContext<{
  openModal: (component: React.ReactNode, props?: {}) => void;
  closeModal: () => void;
} | null>(null);

export const useModal = () => {
  const context = React.useContext(ModalContext);
  if (!context) {
    throw new Error('useModal is outside ModalProvider');
  }
  return context;
};

function ModalProvider({ children }: { children: React.ReactNode }) {
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = React.useState<React.ReactNode | null>(null);
  const [dialogProps, setDialogProps] = React.useState({});

  /**
   * Open dialog modal provider.
   * @param component - React JSX element. <br/><br/>
   * @param props - {open, onClose, renderHeader, renderFooter, type, title, footerOverrides, isLoading, isFormValid, styleOverrides, headerProps, containerProps, bodyProps, footerProps, overlayProps}<br/> <br/> Refer to the botero Dialog component for details.
   */
  const openModal = (component: React.ReactNode, props = {}) => {
    setOpen(true);
    setContent(component);
    setDialogProps(props);
  };

  /**
   * Close dialog modal provider.
   */
  const closeModal = () => {
    setContent(null);
    setOpen(false);
  };

  const value = useMemo(
    () => ({
      openModal,
      closeModal,
    }),
    [openModal, closeModal]
  );
  return (
    <ModalContext.Provider value={value}>
      <Dialog
        style={{ width: 500 }}
        {...dialogProps}
        containerProps={{ style: { scrollbarWidth: 'none' } }}
        onClose={closeModal}
        open={open}
      >
        {content}
      </Dialog>
      {children}
    </ModalContext.Provider>
  );
}

export default ModalProvider;
