const IS_PRODUCTION = () => {
  if (process.env?.TARGET_IS_PRODUCTION) return true;
  return !/staging|localhost/i.test(window.location.href);
};

const REDIRECT_PATH = '/auth/callback';

const GQL_ENDPOINT = IS_PRODUCTION()
  ? 'https://athena.production.botmd.io/gql/'
  : 'https://athena.staging.botmd.io/gql/';

const HIPPO_GQL_ENDPOINT = IS_PRODUCTION()
  ? 'https://hippocrates.production.botmd.io/gql/'
  : 'https://hippocrates.staging.botmd.io/gql/';

const LOGIN_PROVIDER_UID = IS_PRODUCTION()
  ? 'PAUGAw58RelJFUskNLkGF1eKPYP2zenF'
  : 'G9ksGy84lpMDOx7qQSgwvn0OsXfa6Mkb';

const LOGIN_URL = IS_PRODUCTION()
  ? 'https://login.production.botmd.io'
  : 'https://login.staging.botmd.io';

const ORGANIZATIONS = ['ncis', 'sgh', 'fleminghospital', 'ttsh', 'icc'];

export default {
  env: process.env.NODE_ENV,
  IS_PRODUCTION,
  cookie: { name: 'session', maxAge: 2595599 }, // 30 days,
  LOGIN_PROVIDER_UID,
  GQL_ENDPOINT,
  HIPPO_GQL_ENDPOINT,
  LOGIN_URL,
  ORGANIZATIONS,
  REDIRECT_PATH,
};
