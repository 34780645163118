import {
  FAIcon,
  Flex,
  H6,
  InputField,
  Select,
  Text,
  Tooltip,
} from '@fivehealth/botero';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { useState } from 'react';
import {
  InputWithLabelProps,
  SelectWithLabelProps,
} from './patientDetails.types';

function Label(props: any) {
  return <H6 pb="4px" color="darkestShade" {...props} />;
}
export function InputWithLabel({
  label,
  value,
  mr,
  onChange,
  isDisabled = false,
}: InputWithLabelProps) {
  const [active, setActive] = useState(false);
  return (
    <Flex flexDirection="column" mr={mr} flexGrow={1}>
      <Label>{label}</Label>
      <InputField
        disabled={isDisabled}
        type="text"
        inputMode="numeric"
        width="auto"
        maxWidth="auto"
        value={value || ''}
        onChange={onChange}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        style={{
          flexGrow: 1,
          outline: 'none',
          borderColor: active ? '#256BF6' : '#D5D7DE',
        }}
      />
    </Flex>
  );
}
export function SelectWithLabel({
  onChange,
  value,
  label,
  tooltip,
  options,
  isDisabled = false,
}: SelectWithLabelProps) {
  return (
    <Flex
      flexDirection="column"
      pt="16px"
      flex="0 0 50%"
      mt={[1, 0]}
      alignItems="start"
    >
      <Tooltip
        tooltip={<Text color="white">{tooltip}</Text>}
        toolTipOptions={{
          placement: 'top',
        }}
        toolTipElementProps={{
          maxWidth: 275,
          px: 2,
        }}
        style={{
          display: 'flex',
          alignItems: 'baseline',
        }}
      >
        <Label>{label}</Label>
        <FAIcon
          icon={faQuestionCircle}
          hover={{ opacity: 0.6 }}
          fontSize={10}
          style={{
            position: 'relative',
            cursor: 'pointer',
            left: '6px',
            top: '3px',
          }}
        />
      </Tooltip>
      <Select
        width="100%"
        value={value}
        options={options}
        onChange={onChange}
        isDisabled={isDisabled}
      />
    </Flex>
  );
}
