import {
  ActionMenu,
  ActionMenuItem,
  Box,
  FAIcon,
  Flex,
  H4,
  H5,
  useAuth,
} from '@fivehealth/botero';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useCookies } from 'react-cookie';
import { useModal } from '../context/ModalContext';
import Config from '../Config';
import ChangeProfilePicture from './ChangeProfilePicture.moda';

const getProfilePic = () => {
  const avatar = localStorage.getItem('chemocalc-avatar');
  return avatar ? JSON.parse(avatar) : '/avatar_grey_female.svg';
};

// const blobToBase64 = (blob) =>
//   new Promise((resolve) => {
//     const reader = new FileReader();
//     reader.onload = () => {
//       const dataUrl = reader.result;
//       resolve(dataUrl);
//     };
//     reader.readAsDataURL(blob);
//   });

// const getBase64Image = async (url) => {
//   const response = await fetch(url);
//   const blob = await response.blob();
//   const base64 = await blobToBase64(blob);
//   return base64;
// };

// const getProfilePic = (user) => {
//   if (user) {
//     const profilePhoto = _.get(user, 'profilePhoto');
//     if (profilePhoto) {
//       return profilePhoto;
//     }

//     const avatar = _.get(user, 'metadata.avatar');
//     if (avatar) {
//       return `/${avatar}.svg`;
//     }
//   }
//   return '/avatar_blue_male.svg';
// };

function UserInfo({
  userName,
  avatar,
}: {
  userName: string;
  avatar: string;
}): React.ReactElement {
  return (
    <Flex alignItems="center">
      <H5>{userName}</H5>
      <Box ml={1} mr={1} as="img" src={avatar} borderRadius="50%" width={40} />
      <FAIcon icon={faChevronDown} fontSize="18px" />
    </Flex>
  );
}

function ProfileActionMenu({
  label,
  avatar,
  setAvatar,
}: {
  label: React.ReactElement;
  avatar: string;
  setAvatar: (avatar: string) => void;
}) {
  const [cookies] = useCookies([Config.cookie.name]);
  const user = cookies && cookies[Config.cookie.name];
  const { logout } = useAuth();
  const { openModal, closeModal } = useModal();

  function handleLogout() {
    logout();
  }

  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });

  return (
    <ActionMenu label={label} ml={isMobile ? '-16px' : '0px'}>
      <ActionMenuItem
        onClick={() =>
          openModal(
            <ChangeProfilePicture
              closeModal={closeModal}
              isUpdating={false}
              avatar={avatar}
              setAvatar={setAvatar}
            />
          )
        }
      >
        Change profile picture
      </ActionMenuItem>
      <ActionMenuItem>
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://help.botmd.com/article/222-using-ncis-chemo-calc"
        >
          Help guide
        </a>
      </ActionMenuItem>
      <ActionMenuItem style={{ borderBottom: '1px solid #D5D7DE' }}>
        <a
          href="mailto:customersupport@botmd.io?subject=ChemoCalc v2"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact us
        </a>
      </ActionMenuItem>
      {user && user.isMobileApp ? null : (
        <ActionMenuItem onClick={handleLogout}>Log out</ActionMenuItem>
      )}
    </ActionMenu>
  );
}

function Header({ hospital }: { hospital: string }) {
  const [userAvatar, setUserAvatar] = useState(getProfilePic());
  const [cookies] = useCookies([Config.cookie.name]);
  const user = cookies && cookies[Config.cookie.name];
  return (
    <>
      <Box id="nav" display={['initial', 'none']}>
        <Flex justifyContent="space-between" alignItems="center" height={64}>
          <ProfileActionMenu
            setAvatar={setUserAvatar}
            avatar={userAvatar}
            label={
              <Box as="img" src={userAvatar} borderRadius="50%" width={32} />
            }
          />
          <H4 ml={-4}>{hospital.toUpperCase()} ChemoCalc</H4>
          <Flex />
        </Flex>
      </Box>
      <Box display={['none', 'initial']}>
        <Flex py={3} justifyContent="space-between" alignItems="center">
          <Box as="img" src={`/${hospital}_logo.png`} width={153} />
          <H4>{hospital.toUpperCase()} ChemoCalc</H4>
          <ProfileActionMenu
            setAvatar={setUserAvatar}
            avatar={userAvatar}
            label={
              <UserInfo
                userName={`${user?.firstName} ${user?.lastName}`}
                avatar={userAvatar}
              />
            }
          />
        </Flex>
      </Box>
    </>
  );
}

export default Header;
