import { useState } from 'react';
import {
  Body,
  Box,
  Flex,
  H4,
  PrimaryButton,
  useApi,
  useAuth,
} from '@fivehealth/botero';
import { useCookies } from 'react-cookie';
import LoadingIndicator from '../assets/loading.gif';
import { useAppData } from '../context/AppDataContext';
import { useModal } from '../context/ModalContext';
import ErrorModal from './ErrorModal';
import ExportModal from './ExportModal';
import FinalSummary from './FinalSummary';
import InputSummary from './InputSummary';
import RegimenSummary from './RegimenSummary';
import Config from '../Config';

export default function Results() {
  const { authState } = useAuth();
  const { loading, results } = useAppData();
  const { openModal, closeModal } = useModal();
  const [cookies] = useCookies([Config.cookie.name]);
  const user = cookies && cookies[Config.cookie.name];
  const [priceUpdatedDate, setPriceUpdatedDate] = useState('');
  const {
    queries: { useRegimens },
  } = useApi({
    queries: ['useRegimens'],
  });
  useRegimens({
    enabled: authState.authenticated,
    staleTime: Infinity,
    onSuccess: ({ data: { header } }: { data: { header: string } }) =>
      setPriceUpdatedDate(header),
    onError: () => openModal(<ErrorModal message="Server not responding" />),
    variables: {
      miniapp: `${user?.hospital?.toLowerCase()}_chemocalc`,
    },
  });
  return (
    <Box position="relative">
      {loading ? (
        <Flex
          top={0}
          left={0}
          width="100%"
          height="100%"
          style={{
            position: 'absolute',
            zIndex: 1000,
            backgroundColor: 'rgba(255,255,255,0.75)',
          }}
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <img
            style={{ width: '240px', height: '240px' }}
            src={LoadingIndicator}
            alt="Loading spinner indicator"
          />
          <Body fontWight={400} style={{ position: 'relative', top: '-24px' }}>
            Calculating
          </Body>
        </Flex>
      ) : null}
      <Flex justifyContent="space-between" mb={4}>
        <Flex width="60%">
          <Box>
            <H4 mb={0.5}>Results</H4>
            <Body extraSmall color="darkestShade">
              {
                priceUpdatedDate
                  ? `Prices updated ${priceUpdatedDate}`
                  : '' /* Added invisible string(U+3164) to prevent bump after date load */
              }
            </Body>
          </Box>
        </Flex>
        <Flex justifyContent="right">
          <Box>
            <PrimaryButton
              disabled={results.length === 0}
              style={{ fontWeight: 500, borderRadius: '8px' }}
              onClick={() => {
                openModal(<ExportModal closeModal={closeModal} />);
              }}
            >
              Export Results
            </PrimaryButton>
          </Box>
        </Flex>
      </Flex>
      <InputSummary />
      <RegimenSummary />
      <FinalSummary />
      <Box mt={2} mb="20px">
        <Body fontSize="12px" fontWeight={400} color="fullShade">
          List price refers to prices before any subsidies.
          <br />
          Subsidies include any eligible subsidies such as SDL, MAF, PG, MG.
          <br />
          MediShield Life and Medisave claims are automatically applied to SDL
          drugs in the MOH Cancer Drug List.
          <br />
          <br />
          This tool estimates costs for typical regimens and actual costs are
          subject to change. Patient-specific regimens and doses may vary.
          <Box display={[results.length ? 'block' : 'none', 'none']}>
            <br />
            <br />
            If you face issues, please contact{' '}
            <a
              style={{ color: '#256BF6', textDecoration: 'underline' }}
              href="mailto:customersupport@botmd.io?subject=ChemoCalc v2"
              target="_blank"
              rel="noopener noreferrer"
            >
              customersupport@botmd.io
            </a>
            .
          </Box>
          <br />
          <br />
          NCIS ChemoCalc version 2.0.0 Oct 2022
        </Body>
      </Box>
    </Box>
  );
}
