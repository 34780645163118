import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { RegimenObject, SelectedRegimen } from '../components/Regimen.types';
import { Cost } from '../components/Results.types';

const initialState: {
  regimens: RegimenObject[];
  selectedRegimens: SelectedRegimen[];
  results: Cost[];
  loading: boolean;
  setRegimens: (value: RegimenObject[]) => void;
  setSelectedRegimens: (value: SelectedRegimen[]) => void;
  setResults: (value: Cost[]) => void;
  setLoading: (value: boolean) => void;
} = {
  regimens: [],
  selectedRegimens: [],
  results: [],
  loading: false,
  /* eslint-disable @typescript-eslint/no-unused-vars */
  setRegimens: (value) => {},
  setSelectedRegimens: (value) => {},
  setResults: (value) => {},
  setLoading: (value) => {},
};
const AppContext = createContext(initialState);

export function AppDataProvider({ children }: { children: React.ReactNode }) {
  const [appState, setAppState] = useState({
    ...initialState,
  });

  const setRegimens = useCallback(
    (value) => {
      setAppState({
        ...appState,
        regimens: { ...appState.regimens, ...value },
      });
    },
    [appState]
  );

  const setSelectedRegimens = useCallback(
    (value) => {
      setAppState({
        ...appState,
        selectedRegimens: value,
      });
    },
    [appState]
  );

  const setResults = useCallback(
    (value) => {
      setAppState({
        ...appState,
        results: value,
        loading: false, // NOTE: This is a hack to make the loading state work
      });
    },
    [appState]
  );

  const setLoading = useCallback(
    (value) => {
      setAppState({
        ...appState,
        loading: value,
      });
    },
    [appState]
  );

  const contextValue = useMemo(
    () => ({
      ...appState,
      setRegimens,
      setSelectedRegimens,
      setResults,
      setLoading,
    }),
    [appState, setRegimens, setSelectedRegimens, setResults, setLoading]
  );

  return (
    <AppContext.Provider value={contextValue}> {children}</AppContext.Provider>
  );
}

export const useAppData = () => useContext(AppContext);
