import {
  AccordionWidget,
  Badge,
  Body,
  Box,
  FAIcon,
  Flex,
  H4,
  H5,
  H6,
  Text,
  Tooltip,
} from '@fivehealth/botero';
import {
  faChevronDown,
  faExclamationTriangle,
  faQuestionCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { isEqual, uniqWith } from 'lodash';
import { useAppData } from '../context/AppDataContext';
import getSummary from '../utils/resultsSummary';

function numberWithCommas(x: number) {
  const withCommas = (Math.sign(x) * Math.round(Math.abs(x)))
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (withCommas.startsWith('-')) {
    return `-$${withCommas.substring(1)}`;
  }
  return `$${withCommas}`;
}

export default function FinalSummary() {
  const { results } = useAppData();
  const {
    preSubsidyMin,
    preSubsidyMax,
    servicesMin,
    servicesMax,
    subsidiesMin,
    subsidiesMax,
    netPayableMin,
    netPayableMax,
    mediShieldLifeDisplayMin,
    mediShieldLifeDisplayMax,
    minMediSave,
    maxMediSave,
    minPapSub,
    maxPapSub,
    subTotalMin,
    subTotalMax,
    totalMin,
    totalMax,
  } = getSummary(results);

  return (
    <AccordionWidget
      data={[
        {
          id: 'test-2', // NOTE: Use as a unique key for the accordion widget
          name: 'tests',
          isOpen: true,
          data: {},
        },
      ]}
      width="100%"
      renderHeader={(data: any) => (
        <Flex flexDirection="column">
          <Flex
            bg="white"
            justifyContent="space-between"
            flexDirection={['column', 'row']}
          >
            <Flex flexDirection="column">
              <H5 fontWeight={500} color="#697481">
                Final
              </H5>
              <H5 fontWeight={500}>Out of Pocket Costs (Estimated)</H5>
            </Flex>
            <Flex justifyContent="flex-end">
              <Flex flexDirection="column" textAlign="end">
                <H6 fontWeight={600} color="#697481">
                  Out of pocket
                </H6>
                {!results.length && <H5 fontWeight={500}>-</H5>}
                {results.length !== 0 && (
                  <Badge bg="primaryWithOpacity.08">
                    <H5 fontWeight={500}>
                      {totalMin === totalMax
                        ? numberWithCommas(totalMin)
                        : `${numberWithCommas(totalMin)} - ${numberWithCommas(
                            totalMax
                          )}`}{' '}
                      per month
                    </H5>
                  </Badge>
                )}
              </Flex>
              <Body ml={2}>
                <FAIcon
                  icon={faChevronDown}
                  style={{
                    height: 16,
                    transform: `rotate(${data.isOpen ? '180' : '0'}deg)`,
                  }}
                />
              </Body>
            </Flex>
          </Flex>
          {results.length !== 0 && totalMin > 20000 && (
            <Badge borderRadius={8} mt={2} ml={0} py={1} type="alert">
              <Flex alignItems="center">
                <FAIcon
                  icon={faExclamationTriangle}
                  style={{
                    width: 16,
                    marginRight: 4,
                    padding: '0 8px',
                  }}
                  color="danger"
                />
                <Text color="danger" fontWeight={400} fontSize={14}>
                  Cost is above S$20,000. Please check the detailed regimen cost
                  breakdown to verify.
                </Text>
              </Flex>
            </Badge>
          )}
        </Flex>
      )}
      renderBody={() => (
        <>
          <Box
            margin="20px auto"
            height={1}
            width="100%"
            backgroundColor="#D5D7DE"
          />
          <Flex pt={1} pb={1} pl={2} pr={3} justifyContent="space-between">
            <H5 fontWeight={500} color="#697481">
              Pre-Subsidy (CDL Drugs)
            </H5>
            {!results.length && <H5 fontWeight={500}>-</H5>}
            {results.length !== 0 && (
              <H5 fontWeight={500}>
                {preSubsidyMin === preSubsidyMax
                  ? numberWithCommas(preSubsidyMin)
                  : `${numberWithCommas(preSubsidyMin)} - ${numberWithCommas(
                      preSubsidyMax
                    )}`}
              </H5>
            )}
          </Flex>
          <Flex pt={1} pb={1} pl={2} pr={3} justifyContent="space-between">
            <H5 fontWeight={500} color="#697481">
              Subsidies
            </H5>
            {!results.length && <H5 fontWeight={500}>-</H5>}
            {results.length !== 0 && (
              <Badge type="resolve" color="#111824">
                {subsidiesMin === subsidiesMax
                  ? numberWithCommas(-subsidiesMin)
                  : `${numberWithCommas(-subsidiesMin)} - ${numberWithCommas(
                      -subsidiesMax
                    )}`}
              </Badge>
            )}
          </Flex>
          <Flex pt={1} pb={1} pl={2} pr={3} justifyContent="space-between">
            <Tooltip
              tooltip={
                <Text color="white">
                  This is based on drug-specific claim limits from the MOH
                  Cancer Drug List.
                </Text>
              }
              toolTipOptions={{
                placement: 'top',
              }}
              toolTipElementProps={{
                maxWidth: 275,
                px: 2,
              }}
              style={{
                display: 'flex',
                alignItems: 'baseline',
              }}
            >
              <H5 fontWeight={500} color="#697481">
                MediShield Life
              </H5>
              <FAIcon
                icon={faQuestionCircle}
                hover={{ opacity: 0.6 }}
                fontSize={10}
                style={{
                  position: 'relative',
                  cursor: 'pointer',
                  left: '6px',
                  top: '3px',
                }}
              />
            </Tooltip>
            {!results.length && <H5 fontWeight={500}>-</H5>}
            {results.length !== 0 && (
              <Badge type="resolve" color="#111824">
                {mediShieldLifeDisplayMin === mediShieldLifeDisplayMax
                  ? numberWithCommas(-mediShieldLifeDisplayMin)
                  : `${numberWithCommas(
                      -mediShieldLifeDisplayMin
                    )} - ${numberWithCommas(-mediShieldLifeDisplayMax)}`}
              </Badge>
            )}
          </Flex>
          <Box
            margin="12px auto"
            height={1}
            width="calc(100% - 24px)"
            backgroundColor="#D5D7DE"
          />
          <Flex pt={1} pb={1} pl={2} pr={3} justifyContent="space-between">
            <H5 fontWeight={500} color="#697481">
              Net Amount Payable (CDL Drugs)
            </H5>
            {!results.length && <H5 fontWeight={500}>-</H5>}
            {results.length !== 0 && (
              <H5 fontWeight={500}>
                {netPayableMin === netPayableMax
                  ? numberWithCommas(netPayableMin)
                  : `${numberWithCommas(netPayableMin)} - ${numberWithCommas(
                      netPayableMax
                    )}`}
              </H5>
            )}
          </Flex>
          <Flex pt={1} pb={1} pl={2} pr={3} justifyContent="space-between">
            <Tooltip
              tooltip={
                <Text color="white">
                  This is based on drug-specific withdrawal limits from the MOH
                  Cancer Drug List.
                </Text>
              }
              toolTipOptions={{
                placement: 'top',
              }}
              toolTipElementProps={{
                maxWidth: 275,
                px: 2,
              }}
              style={{
                display: 'flex',
                alignItems: 'baseline',
              }}
            >
              <H5 fontWeight={500} color="#697481">
                MediSave
              </H5>
              <FAIcon
                icon={faQuestionCircle}
                hover={{ opacity: 0.6 }}
                fontSize={10}
                style={{
                  position: 'relative',
                  cursor: 'pointer',
                  left: '6px',
                  top: '3px',
                }}
              />
            </Tooltip>
            {!results.length && <H5 fontWeight={500}>-</H5>}
            {results.length !== 0 && (
              <Badge type="resolve" color="#111824">
                {minMediSave === maxMediSave
                  ? numberWithCommas(minMediSave)
                  : `${numberWithCommas(minMediSave)} - ${numberWithCommas(
                      maxMediSave
                    )}`}
              </Badge>
            )}
          </Flex>
          {(maxPapSub !== 0 || minPapSub !== 0) && (
            <Flex pt={1} pb={1} pl={2} pr={3} justifyContent="space-between">
              <H5 fontWeight={500} color="#697481">
                Patient Assistance Program
              </H5>
              <Badge type="resolve" color="#111824">
                {minPapSub === maxPapSub
                  ? numberWithCommas(minPapSub)
                  : `${numberWithCommas(minPapSub)} - ${numberWithCommas(
                      maxPapSub
                    )}`}
              </Badge>
            </Flex>
          )}
          <Box
            margin="12px auto"
            height={1}
            width="calc(100% - 24px)"
            backgroundColor="#D5D7DE"
          />
          <Box>
            <Flex pt={1} pb={1} pl={2} pr={3} justifyContent="space-between">
              <Box flex={[0, 1]} />
              <H5 flex={1} fontWeight={500} color="#697481">
                Subtotal: Out of Pocket (CDL Drugs)
              </H5>
              {!results.length && <H5 fontWeight={500}>-</H5>}
              {results.length !== 0 && (
                <H5 flex={1} textAlign="right" fontWeight={500}>
                  {subTotalMin === subTotalMax
                    ? numberWithCommas(subTotalMin)
                    : `${numberWithCommas(subTotalMin)} - ${numberWithCommas(
                        subTotalMax
                      )}`}
                </H5>
              )}
            </Flex>
            <Flex pt={1} pb={1} pl={2} pr={3} justifyContent="space-between">
              <Box flex={[0, 1]} />
              <Tooltip
                flex={1}
                tooltip={
                  <Text color="white">
                    This is NOT exhaustive. Includes estimates on infusion
                    appointments, preparation fees and certain consumables. Does
                    NOT include other costs like consultation fees, lab tests
                    etc. Cancer services are subject to annual MediShield Life
                    and MediSave claimable amounts which are not factored here.
                  </Text>
                }
                toolTipOptions={{
                  placement: 'top',
                }}
                toolTipElementProps={{
                  maxWidth: 275,
                  px: 2,
                }}
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                }}
              >
                <H5 fontWeight={500} color="#697481">
                  Other Drugs & Services (Subsidised)
                </H5>
                <FAIcon
                  icon={faQuestionCircle}
                  hover={{ opacity: 0.6 }}
                  fontSize={10}
                  style={{
                    position: 'relative',
                    cursor: 'pointer',
                    left: '6px',
                    top: '3px',
                  }}
                />
              </Tooltip>
              {!results.length && <H5 fontWeight={500}>-</H5>}
              {results.length !== 0 && (
                <H5 flex={1} textAlign="right" fontWeight={500}>
                  {servicesMin === servicesMax
                    ? numberWithCommas(servicesMin)
                    : `${numberWithCommas(servicesMin)} - ${numberWithCommas(
                        servicesMax
                      )}`}
                </H5>
              )}
            </Flex>
            <Flex
              pt={2}
              pb={2}
              pl={2}
              pr={3}
              borderRadius="8px"
              backgroundColor="primaryWithOpacity.08"
            >
              <Box flex={[0, 1]} />
              <H5 flex={1}>Total: Estimated Out of Pocket</H5>
              {!results.length && <H4>-</H4>}
              {results.length !== 0 && (
                <H4 flex={1} textAlign="right">
                  {totalMin === totalMax
                    ? numberWithCommas(totalMin)
                    : `${numberWithCommas(totalMin)} - ${numberWithCommas(
                        totalMax
                      )}`}{' '}
                  per month
                </H4>
              )}
            </Flex>
            {results.length !== 0 &&
              results.some(
                (result) => result.disclaimer && result.disclaimer.length
              ) && (
                <Body mt={2} fontSize="12px">
                  Note:
                  <br />
                  {uniqWith(
                    results
                      .filter((result) => result.disclaimer.length)
                      .map((result) => result.disclaimer.split('\n'))
                      .flat(1)
                      .filter((line) => line.length),
                    isEqual
                  ).map((line, lineIndex) => (
                    <Box key={line}>
                      {lineIndex !== 0 && <br />}
                      <Text>{line}</Text>
                    </Box>
                  ))}
                </Body>
              )}
          </Box>
        </>
      )}
      containerProps={{
        padding: '16px',
        border: '1px solid #D5D7DE',
        borderRadius: '8px',
        marginTop: '16px',
      }}
      headerProps={{
        width: '100%',
        backgroundColor: 'white',
        border: 0,
      }}
      bodyProps={{
        backgroundColor: 'white',
        minHeight: 50,
        paddingRight: '10px',
      }}
      alternateBgColor={false}
    />
  );
}
