import React from 'react';
import {
  H4,
  Body,
  Flex,
  PrimaryButton,
  SecondaryOutlinedButton,
} from '@fivehealth/botero';
import BotCrying from '../assets/cry.png';

function ErrorModal({ message }: { message: string }) {
  return (
    <Flex padding={32} flexDirection="column" alignItems="center">
      <img width="120px" src={BotCrying} alt="bot md logo crying" />
      <H4 mt={4}>Error: {message}</H4>
      <Body fontSize="14px" textAlign="center" color="#697481" width="90%">
        Please refresh the page. If you continue seeing this error, please
        contact{' '}
        <a
          style={{ color: '#256BF6', textDecoration: 'underline' }}
          href="mailto:customersupport@botmd.io?subject=ChemoCalc v2"
          target="_blank"
          rel="noopener noreferrer"
        >
          customersupport@botmd.io
        </a>
      </Body>
      <Flex mt={4}>
        <SecondaryOutlinedButton fontWeight={500}>
          <a
            style={{ color: '#697481' }}
            href="mailto:customersupport@botmd.io?subject=ChemoCalc v2"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact support
          </a>
        </SecondaryOutlinedButton>
        <PrimaryButton
          fontWeight={500}
          ml={3}
          onClick={() => {
            window.location.reload();
          }}
        >
          Refresh page
        </PrimaryButton>
      </Flex>
    </Flex>
  );
}

export default ErrorModal;
