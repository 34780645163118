import { Body, Box, FAIcon, Flex, H2, PrimaryButton } from '@fivehealth/botero';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

function CDLIndicationModal({
  drug,
  body,
  closeModal,
}: {
  drug: string;
  body: string;
  closeModal: () => void;
}) {
  return (
    <>
      <Flex
        style={{ overflow: 'hidden' }}
        maxWidth="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <H2>{drug}</H2>
        <Box cursor="pointer" onClick={closeModal}>
          <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
        </Box>
      </Flex>
      <Box mt={6}>
        {body.split('\n').map((line, index) => (
          <Body minHeight={line.length ? 0 : 32} color="fullShade" key={index}>
            {line}
          </Body>
        ))}
      </Box>
      <Flex mt={4} pt={4} justifyContent="flex-end" alignItems="center">
        <PrimaryButton py="12px" px={3} onClick={closeModal}>
          Close
        </PrimaryButton>
      </Flex>
    </>
  );
}

export default CDLIndicationModal;
