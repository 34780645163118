const PCHI_OPTIONS = [
  {
    label: '$0 < PCHI ≤ $1200 [75% SDL & 75% MAF Subsidy]',
    value: 600,
  },
  {
    label: '$1200 < PCHI ≤ $2000 [75% SDL & 75% MAF Subsidy]',
    value: 1600,
  },
  {
    label: '$2000 < PCHI ≤ $3300 [50 % SDL & 50% MAF Subsidy]',
    value: 3000,
  },
  {
    label: '$3300 < PCHI ≤ $6500 [50 % SDL & 40 % MAF Subsidy]',
    value: 5000,
  },
  { label: 'PCHI > $6500 [50 % SDL Subsidy]', value: 10000 },
];

const DRUG_CHOICE_OPTIONS = [
  { label: 'Generic or Biosimilar', value: 'generic or biosimilar' },
  { label: 'Branded', value: 'branded' },
];

const SUBSIDY_OPTIONS = [
  { label: 'Subsidised', value: 'subsidised' },
  { label: 'Private', value: 'private' },
  { label: 'Non-Resident', value: 'non-resident' },
];

const RESIDENCY_OPTIONS = [
  { label: 'Singapore Citizen', value: 'sc' },
  { label: 'Permanent Resident', value: 'pr' },
];

const GENERATION_OPTIONS = [
  { label: 'NIL', value: '' },
  { label: 'Merdeka Generation', value: 'mg' },
  { label: 'Pioneer Generation', value: 'pg' },
];

export {
  PCHI_OPTIONS,
  DRUG_CHOICE_OPTIONS,
  SUBSIDY_OPTIONS,
  RESIDENCY_OPTIONS,
  GENERATION_OPTIONS,
};
