import {
  Box,
  FAIcon,
  Flex,
  H2,
  PrimaryButton,
  SecondaryOutlinedButton,
  theme,
} from '@fivehealth/botero';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import _ from 'lodash';
import { useState } from 'react';
import styled, { css } from 'styled-components';

const StyledBorder = styled(Flex)`
  border-radius: 50%;
  border: 2px solid transparent;
  transition: border 0.1s linear;
  cursor: pointer;
  ${({ active }: { active: boolean }) =>
    active &&
    css`
      border-color: ${theme.colors.primary};
    `}
  &:hover {
    border-color: ${theme.colors.primary};
  }
`;

const AllAvatarImages = [
  { id: 'AVATAR_BLUE_MALE', src: '/avatar_blue_male.svg' },
  { id: 'AVATAR_YELLOW_MALE', src: '/avatar_yellow_male.svg' },
  { id: 'AVATAR_PINK_MALE', src: '/avatar_pink_male.svg' },
  { id: 'AVATAR_GREY_MALE', src: '/avatar_grey_male.svg' },
  { id: 'AVATAR_BLUE_FEMALE', src: '/avatar_blue_female.svg' },
  { id: 'AVATAR_YELLOW_FEMALE', src: '/avatar_yellow_female.svg' },
  { id: 'AVATAR_PINK_FEMALE', src: '/avatar_pink_female.svg' },
  { id: 'AVATAR_GREY_FEMALE', src: '/avatar_grey_female.svg' },
  { id: 'AVATAR_BUG', src: '/avatar_bug.svg' },
];

interface AvatarImageProps {
  active: boolean;
  onClick: () => void;
  src: string;
}

function AvatarImage({ active, onClick, src, ...props }: AvatarImageProps) {
  return (
    <StyledBorder mx={1} mb={2} active={active} position="relative">
      {active && (
        <Flex
          position="absolute"
          top={1}
          right={-12}
          bg="white"
          borderRadius="50%"
          border="2px solid"
          borderColor="white"
        >
          <FAIcon icon={faCheckCircle} color="primary" />
        </Flex>
      )}
      <Box
        border="4px solid"
        borderColor="white"
        borderRadius="50%"
        width="50px"
        as="img"
        src={src}
        onClick={onClick}
        {...props}
      />
    </StyledBorder>
  );
}

interface ChangeProfilePictureProps {
  closeModal: () => void;
  isUpdating: boolean;
  avatar?: string;
  setAvatar: (avatar: string) => void;
}

function ChangeProfilePicture({
  avatar,
  setAvatar,
  closeModal,
  isUpdating,
}: ChangeProfilePictureProps) {
  // Uppercase to convert into gql enum for Avatar
  const [selectedAvatar, setSelectedAvatar] = useState(avatar);

  const handleAvatarChange = () => {
    if (selectedAvatar) {
      localStorage.setItem('chemocalc-avatar', JSON.stringify(selectedAvatar));
      setAvatar(selectedAvatar);
    }
    closeModal();
  };

  return (
    <>
      <Flex
        style={{ overflow: 'hidden' }}
        maxWidth="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <H2>Change profile picture</H2>
        <Box cursor="pointer" onClick={closeModal}>
          <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
        </Box>
      </Flex>
      <Flex mt={3} mx={-1} flexWrap="wrap">
        {_.map(AllAvatarImages, (avatarObj, index) => (
          <AvatarImage
            key={index}
            onClick={() => setSelectedAvatar(avatarObj.src)}
            active={_.isEqual(selectedAvatar, avatarObj.src)}
            src={avatarObj.src}
          />
        ))}
      </Flex>
      <Flex mt={4} justifyContent="flex-end" alignItems="center">
        <SecondaryOutlinedButton mr={3} onClick={closeModal}>
          Cancel
        </SecondaryOutlinedButton>
        <PrimaryButton disabled={isUpdating} onClick={handleAvatarChange}>
          {isUpdating ? `Updating...` : 'Update avatar'}
        </PrimaryButton>
      </Flex>
    </>
  );
}

export default ChangeProfilePicture;
