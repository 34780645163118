import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  query chernobylChemocalcRegimen($miniapp: String!) {
    chernobylChemocalcRegimens(miniapp: $miniapp) {
      header
      regimens {
        regimen
        synonyms
        doseMethods
        brandStatus
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'chernobylChemocalcRegimen',
  keepPreviousData: true,
  select: ({
    chernobylChemocalcRegimens,
  }: {
    chernobylChemocalcRegimens: any;
  }) => chernobylChemocalcRegimens,
});
