import { createQuery } from '@fivehealth/botero';
import { gql } from 'graphql-request';

export const GRAPHQL_DOCUMENT_CHEMOCALC_COST = gql`
  query chernobylChemocalcCost(
    $miniapp: String!
    $regimen: String!
    $numCycles: Int!
    $height: Int
    $weight: Int
    $phci: Int
    $residency: String
    $generation: String
    $subsidy: String
    $mafDrugs: [String!]
    $brandStatus: String
    $drugIndications: [GenericScalar!]
  ) {
    chernobylChemocalcCost(
      miniapp: $miniapp
      regimen: $regimen
      numCycles: $numCycles
      height: $height
      weight: $weight
      phci: $phci
      brandStatus: $brandStatus
      residency: $residency
      generation: $generation
      mafDrugs: $mafDrugs
      subsidyStatus: $subsidy
      drugIndications: $drugIndications
    ) {
      userInputs {
        regimen
        regimen
        height
        weight
        bsa
        phci
        residency
        generation
        brandStatus
        subsidyStatus
        mafDrugs
        numCycles
        drugIndications {
          drug
          maf
          cdl
          indications
        }
      }
      cost {
        msWithdrawPerMonth
        mslClaimPerMonth
        mslCoPayPerMonth
        papSubPerMonth
        breakdownBaseCost
        cycleLength
        baseCost
        sdlSubsidy
        mafSubsidy
        servicesCost
      }
      regimenFormularyKnapsack {
        drug {
          drug
          dosePerUnit
          doseUnit
          dosePerCycle
          doseMethod
          totalDose
          doseRate
        }
        formularyItems {
          formularyItem
          sdlPrice
          mafPrice
          basePrice
          quantityPerDose
          dosePerCycle
          alternates
          doseRate
        }
      }
      mafIndications {
        drug
        indication
      }
      disclaimer
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT_CHEMOCALC_COST,
  queryType: 'query',
  baseQueryKey: 'chernobylChemocalcCost',
  keepPreviousData: true,
});
