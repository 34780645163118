import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '@fivehealth/botero';
import Config from '../Config';

function Login() {
  const history = useHistory();
  const location = useLocation();
  const { authState } = useAuth();

  const params = new URLSearchParams(location.search);

  const redirectUrl = `${window.location.origin}${Config.REDIRECT_PATH}`;
  const loginUrl = `${Config.LOGIN_URL}/?uid=${Config.LOGIN_PROVIDER_UID}&redirectTo=${redirectUrl}`;

  const applicationInput = JSON.stringify({
    organization_key: params.get('hospital'),
    allow_guest: false,
  });

  useEffect(() => {
    // redirect authenticated user
    if (authState.authenticated) {
      const path = `/${params.get('hospital')}`;
      return history.push(path);
    }
    const themeLogo = JSON.stringify({
      logo: `https://botmd-production-hippocrates-static.s3.amazonaws.com/hospital/${params
        .get('hospital')
        ?.toUpperCase()}/${params
        .get('hospital')
        ?.toUpperCase()}_transparent.png`,
    });
    // redirect to login
    if (params.get('hospital')) {
      return window.location.replace(
        `${loginUrl}&applicationInput=${applicationInput}&theme=${themeLogo}`
      );
    }

    // not authenticated & missing organization key then redirect to home
    return history.push('/error');
  }, [authState, applicationInput]);

  return <section>Redirecting...</section>;
}

export default Login;
