import { Box, Flex, H4, useApi } from '@fivehealth/botero';
import { isEqual, uniqBy, uniqWith } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { GRAPHQL_DOCUMENT_CHEMOCALC_COST } from '../../api/queries/useCost';
import Config from '../../Config';
import { useAppData } from '../../context/AppDataContext';
import { useModal } from '../../context/ModalContext';
import IndicationBox from '../drugIndications/IndicationBox';
import ErrorModal from '../ErrorModal';
import { Cost, DrugIndication } from '../Results.types';
import { InputWithLabel, SelectWithLabel } from './FormElements';
import {
  DRUG_CHOICE_OPTIONS,
  GENERATION_OPTIONS,
  PCHI_OPTIONS,
  RESIDENCY_OPTIONS,
  SUBSIDY_OPTIONS,
} from './patientDetails.constants';
import {
  AdditionalDetailsType,
  PatientDetailsType,
  SelectOptions,
  SubsidiesType,
} from './patientDetails.types';

let regimenDrugIndications: any = [];
const regimenDrugMappings: { [key: string]: string[] } = {};

export default function PatientDetails() {
  const [patientDetails, setPatientDetails] = useState<PatientDetailsType>({
    height: null,
    weight: null,
  });

  const prevPatientDetailsRef = useRef<PatientDetailsType>();
  useEffect(() => {
    prevPatientDetailsRef.current = patientDetails;
  });
  const prevPatientDetails = prevPatientDetailsRef.current;

  const [subsidies, setSubsidies] = useState<SubsidiesType>({
    subsidy: 'subsidised',
    residency: 'sc',
    generation: '',
    phci: 3000,
  });
  const [additionalDetails, setAdditionalDetails] =
    useState<AdditionalDetailsType>({
      brandStatus: 'generic or biosimilar',
      mafDrugs: [],
    });
  const [forceRecalculate, setForceRecalculate] = useState(false);

  const [cookies] = useCookies([Config.cookie.name]);
  const user = cookies && cookies[Config.cookie.name];

  const { selectedRegimens, results, setResults, loading, setLoading } =
    useAppData();

  const { openModal } = useModal();
  const { client } = useApi();

  const combinedDoseMethods = new Set(
    selectedRegimens
      .map((selectedRegimen) => selectedRegimen.regimen.doseMethods)
      .flat()
  );

  let ready = selectedRegimens.length > 0;
  if (
    combinedDoseMethods.has('per m2') &&
    (!patientDetails.height || !patientDetails.weight)
  ) {
    ready = false;
  } else if (combinedDoseMethods.has('per kg') && !patientDetails.weight) {
    ready = false;
  }

  const shouldShowDrugIndications = subsidies.subsidy !== 'non-resident';

  const displayStatus: { height: boolean; weight: boolean } = {
    height: false,
    weight: false,
  };

  displayStatus.height = selectedRegimens.some(
    ({ regimen }) =>
      regimen.doseMethods.length && regimen.doseMethods.includes('per m2')
  );

  displayStatus.weight = selectedRegimens.some(
    ({ regimen }) =>
      regimen.doseMethods.length &&
      (regimen.doseMethods.includes('per kg') ||
        regimen.doseMethods.includes('per m2'))
  );

  useEffect(() => {
    if (patientDetails.height && !displayStatus.height) {
      setPatientDetails({
        ...patientDetails,
        height: null,
      });
    }
    if (patientDetails.weight && !displayStatus.weight) {
      setPatientDetails({
        ...patientDetails,
        weight: null,
      });
    }
  }, [displayStatus]);

  useEffect(() => {
    if (selectedRegimens.length === 0 && prevPatientDetails) {
      setSubsidies({
        subsidy: 'subsidised',
        residency: 'sc',
        generation: '',
        phci: 3000,
      });
    }
  }, [selectedRegimens.length, shouldShowDrugIndications]);

  useEffect(() => {
    if (
      (selectedRegimens.length === 0 || shouldShowDrugIndications) &&
      regimenDrugIndications.length
    ) {
      regimenDrugIndications = [];
    }
  }, [shouldShowDrugIndications]);

  const updateDrugIndications = (resultsData: Cost[]) => {
    const allDrugs = uniqBy(
      resultsData.map((result) => result.userInputs.drugIndications).flat(1),
      'drug'
    ).map((drugIndication) => drugIndication.drug);

    regimenDrugIndications = regimenDrugIndications.filter((drug: any) =>
      allDrugs.includes(drug.drug)
    );
  };

  const updateRegimenDrugMappings = (drugs: string[], regimen: string) => {
    if (regimenDrugMappings[regimen]) {
      regimenDrugMappings[regimen] = [
        ...regimenDrugMappings[regimen],
        ...Array.from(new Set(drugs)),
      ];
    } else {
      regimenDrugMappings[regimen] = drugs;
    }
  };

  const filteredSubsidies = useMemo(
    () =>
      Object.keys(subsidies)
        .filter(
          (key) =>
            key === 'subsidy' ||
            (key === 'residency' && subsidies.subsidy !== 'non-resident') ||
            (key === 'generation' &&
              subsidies.subsidy === 'subsidised' &&
              subsidies.residency === 'sc') ||
            (key === 'phci' && subsidies.subsidy === 'subsidised')
        )
        .reduce(
          (cur, key) => Object.assign(cur, { [key]: (subsidies as any)[key] }),
          {}
        ),
    [subsidies]
  );

  const calculateCost = () => {
    if (loading) {
      return;
    }
    if (ready) {
      if (
        selectedRegimens.some(
          (selectedRegimen) => selectedRegimen.regimen.doseMethods.length === 0
        )
      ) {
        openModal(<ErrorModal message="Regimen has empty dose methods." />);
        return;
      }
      setLoading(true);
      const brandedChoice = selectedRegimens.some(
        (selectedRegimen) =>
          selectedRegimen.regimen.brandStatus.includes('branded') &&
          selectedRegimen.regimen.brandStatus.includes('generic or biosimilar')
      );

      Promise.all(
        selectedRegimens.map((selectedRegimen) => {
          let drugIndications = [];

          const drugsForCurrentRegimen =
            regimenDrugMappings[selectedRegimen.regimen.regimen];

          if (drugsForCurrentRegimen) {
            updateRegimenDrugMappings(
              drugsForCurrentRegimen,
              selectedRegimen.regimen.regimen
            );
            drugIndications = regimenDrugIndications.filter(
              (o: any) => drugsForCurrentRegimen?.indexOf(o.drug) > -1
            );
          }
          return client.request(GRAPHQL_DOCUMENT_CHEMOCALC_COST, {
            ...patientDetails,
            ...additionalDetails,
            ...filteredSubsidies,
            drugIndications,
            brandStatus: brandedChoice ? additionalDetails.brandStatus : '',
            regimen: selectedRegimen.regimen.regimen,
            numCycles: selectedRegimen.cycles,
            miniapp: `${user.hospital?.toLowerCase()}_chemocalc`,
          });
        })
      )
        .then((response) => {
          const newCost = response.map(
            (result) => result.chernobylChemocalcCost
          );
          newCost.forEach((result: any) => {
            const drugIndications = result.userInputs.drugIndications.map(
              (o: any) => o.drug
            );
            if (
              !(result.userInputs.regimen in regimenDrugMappings) &&
              Object.values(regimenDrugMappings).some((drugs: string[]) =>
                drugs.some((drug) => drugIndications.includes(drug))
              )
            ) {
              setForceRecalculate(true);
            }
            updateRegimenDrugMappings(
              result.userInputs.drugIndications.map((o: any) => o.drug),
              result.userInputs.regimen
            );
          });

          updateDrugIndications(newCost);
          setResults(newCost);
        })
        .catch(() => {
          openModal(<ErrorModal message="Regimen error" />);
        });
    } else {
      setResults([]);
    }
  };

  useEffect(() => {
    if (prevPatientDetails === patientDetails) {
      // No need for debounce if patient details haven't changed
      calculateCost();
      if (forceRecalculate) {
        setForceRecalculate(false);
      }
      return () => {};
    }
    const timeoutId = setTimeout(calculateCost, 1000);
    return () => clearTimeout(timeoutId);
  }, [
    patientDetails,
    subsidies,
    additionalDetails,
    selectedRegimens,
    forceRecalculate,
    ready,
  ]);

  if (!selectedRegimens.some(({ regimen }) => regimen.doseMethods.length)) {
    return null;
  }

  const handleDrugIndicationChange = (drugIndication: DrugIndication) => {
    const drugIndex = regimenDrugIndications.findIndex(
      (indication: any) => indication.drug === drugIndication.drug
    );

    if (drugIndex > -1) {
      regimenDrugIndications[drugIndex] = drugIndication;
    } else {
      regimenDrugIndications.push(drugIndication);
    }

    calculateCost();
  };

  return (
    <Box style={{ opacity: loading ? 0.4 : 1 }} width="calc(100% - 22px)">
      {selectedRegimens.some(
        ({ regimen }) =>
          regimen.doseMethods.length &&
          !(
            regimen.doseMethods.length === 1 &&
            regimen.doseMethods[0] === 'fixed'
          )
      ) && (
        <>
          <H4 mt="40px">Patient Details</H4>
          <Flex mt={2}>
            {selectedRegimens.some(
              ({ regimen }) =>
                regimen.doseMethods.length &&
                regimen.doseMethods.includes('per m2')
            ) && (
              <InputWithLabel
                isDisabled={loading}
                mr={1}
                value={patientDetails.height || ''}
                label="Height (cm)"
                onChange={
                  loading
                    ? () => {}
                    : (e: React.FormEvent<HTMLInputElement>) => {
                        const value = Number.parseInt(
                          e.currentTarget.value,
                          10
                        );
                        if (value <= 230 || Number.isNaN(value)) {
                          setPatientDetails({
                            ...patientDetails,
                            height: value,
                          });
                        }
                      }
                }
              />
            )}
            {selectedRegimens.some(
              ({ regimen }) =>
                regimen.doseMethods.length &&
                (regimen.doseMethods.includes('per kg') ||
                  regimen.doseMethods.includes('per m2'))
            ) && (
              <InputWithLabel
                isDisabled={loading}
                mr={0}
                value={patientDetails.weight || ''}
                onChange={
                  loading
                    ? () => {}
                    : (e: React.FormEvent<HTMLInputElement>) => {
                        const value = Number.parseInt(
                          e.currentTarget.value,
                          10
                        );
                        if (value <= 200 || Number.isNaN(value)) {
                          setPatientDetails({
                            ...patientDetails,
                            weight: value,
                          });
                        }
                      }
                }
                label="Weight (kg)"
              />
            )}
          </Flex>
        </>
      )}
      <H4 mt="40px">Subsidy Details</H4>
      <SelectWithLabel
        isDisabled={loading}
        label="Subsidy Status"
        tooltip={
          <>
            Subsidised patients are eligible for subsidies.
            <br />
            Subsidised and Private patients are eligible for MediSave and
            MediShield Life.
            <br />
            Non-residents are presumed to be ineligible for MediSave and
            MediShield Life.
          </>
        }
        value={SUBSIDY_OPTIONS.find(
          (option) => option.value === subsidies.subsidy
        )}
        options={SUBSIDY_OPTIONS}
        onChange={
          loading
            ? () => {}
            : (option: SelectOptions) => {
                setSubsidies({
                  ...subsidies,
                  subsidy: option.value,
                  generation:
                    option.value !== 'subsidised' ? '' : subsidies.generation,
                });
              }
        }
      />
      {(subsidies.subsidy === 'subsidised' ||
        subsidies.subsidy === 'private') && (
        <SelectWithLabel
          isDisabled={loading}
          value={RESIDENCY_OPTIONS.find(
            (option) => option.value === subsidies.residency
          )}
          label="Residency Status"
          tooltip={
            <>
              Subsidised patients are eligible for standard drug list (SDL)
              subsidies.
              <br />
              Singapore Citizens may be eligible for additional subsidies like
              Medication Assistance Fund (MAF), Pioneer Generation (PG) and
              Merdeka Generation (MG) subsidies.
            </>
          }
          options={RESIDENCY_OPTIONS}
          onChange={
            loading
              ? () => {}
              : (option: SelectOptions) => {
                  setSubsidies({ ...subsidies, residency: option.value });
                }
          }
        />
      )}
      {subsidies.subsidy === 'subsidised' && subsidies.residency === 'sc' && (
        <SelectWithLabel
          isDisabled={loading}
          value={GENERATION_OPTIONS.find(
            (option) => option.value === subsidies.generation
          )}
          label="Additional Subsidies"
          tooltip={
            <>
              Pioneer Generation: Born in or before 1949 and became a Singapore
              citizen on or before 31 Dec 1986
              <br />
              Merdeka Generation: Born in 1950-1959 and became a Singapore
              citizen on or before 31 Dec 1996
            </>
          }
          options={GENERATION_OPTIONS}
          onChange={
            loading
              ? () => {}
              : (option: SelectOptions) => {
                  setSubsidies({ ...subsidies, generation: option.value });
                }
          }
        />
      )}
      {subsidies.subsidy === 'subsidised' &&
        (subsidies.residency === 'sc' || subsidies.residency === 'pr') && (
          <SelectWithLabel
            isDisabled={loading}
            value={PCHI_OPTIONS.find(
              (option) => option.value === subsidies.phci
            )}
            label="PCHI $ per Month"
            tooltip={
              <>
                PCHI: Per Capita Household Income
                <br />
                MAF for PR capped at 20%
                <br />
                SDL for PR capped at 25%
                <br />
                MAF/SDL for SC as listed according to PCHI
              </>
            }
            options={PCHI_OPTIONS}
            onChange={
              loading
                ? () => {}
                : (option: SelectOptions) => {
                    setSubsidies({ ...subsidies, phci: option.value });
                  }
            }
          />
        )}
      {(selectedRegimens.some(
        (selectedRegimen) =>
          selectedRegimen.regimen.brandStatus.includes('branded') &&
          selectedRegimen.regimen.brandStatus.includes('generic or biosimilar')
      ) ||
        uniqWith(
          results.map((result) => result.mafIndications).flat(1),
          isEqual
        ).length > 0) && <H4 mt="40px">Additional Details</H4>}
      {selectedRegimens.some(
        (selectedRegimen) =>
          selectedRegimen.regimen.brandStatus.includes('branded') &&
          selectedRegimen.regimen.brandStatus.includes('generic or biosimilar')
      ) && (
        <SelectWithLabel
          isDisabled={loading}
          value={DRUG_CHOICE_OPTIONS.find(
            (option) => option.value === additionalDetails.brandStatus
          )}
          label="Choice of Drug"
          tooltip={
            <>
              Where the formulary contains both branded and generic/biosimilar
              formulation(s), a choice may be made. Note: Subsidy class may
              differ between branded and generic/biosimilar.
            </>
          }
          options={DRUG_CHOICE_OPTIONS}
          onChange={
            loading
              ? () => {}
              : (option: SelectOptions) => {
                  setAdditionalDetails({
                    ...additionalDetails,
                    brandStatus: option.value,
                  });
                }
          }
        />
      )}
      {shouldShowDrugIndications &&
        uniqBy(
          results.map((result) => result.userInputs.drugIndications).flat(1),
          'drug'
        ).map(({ drug, indications, maf, cdl }) => (
          <IndicationBox
            key={drug}
            drug={drug}
            maf={maf}
            cdl={cdl}
            indications={indications}
            updateIndications={handleDrugIndicationChange}
          />
        ))}
    </Box>
  );
}
