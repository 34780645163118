import {
  Body,
  Box,
  Flex,
  H1,
  H3,
  H4,
  Input,
  PrimaryButton,
  Text,
} from '@fivehealth/botero';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Bg from '../assets/Bg.png';
import Logo from '../assets/botmd_logo.svg';
import Config from '../Config';

function InformationSection() {
  return (
    <Box display={['none', 'none', 'block']} flex={1}>
      <div
        style={{
          backgroundImage: `url(${Bg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Flex
          minHeight="100vh"
          p={9}
          pb={6}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Flex flexDirection="column" maxWidth="350px">
            <H3 fontSize="24px" lineHeight="24px" color="white" mb={3}>
              Welcome to ChemoCalc
            </H3>
            <Body color="white" fontSize="16px" lineHeight="24px">
              ChemoCalc enables frontline healthcare professionals to instantly
              estimate the monthly out-of-pocket expenses for their cancer
              patients based on their own chemotherapy regimens, drug prices and
              public and private insurance schemes.
              <br />
              <br />
              Contact{' '}
              <a
                style={{ textDecoration: 'underline' }}
                href="mailto:sales@botmd.io?subject=ChemoCalc v2"
                target="_blank"
                rel="noopener noreferrer"
              >
                sales@botmd.io
              </a>{' '}
              if you are interested to learn more.
            </Body>
          </Flex>
        </Flex>
      </div>
    </Box>
  );
}

function Home() {
  const history = useHistory();
  const [org, setOrg] = useState({ value: '', error: '' });
  const handleOrgSubmit = () => {
    if (!Config.ORGANIZATIONS.includes(org.value.toLowerCase())) {
      setOrg({
        value: org.value,
        error: 'We could not find your organization',
      });
    } else {
      history.push(`/login?hospital=${org.value.toLowerCase()}`);
    }
  };
  return (
    <Flex minHeight="100vh" flexDirection={['column', 'column', 'row']}>
      <Flex p={[0, 8]} flex={1} width="100%">
        <Box width="100%">
          <Flex
            flex={1}
            height="100%"
            p={['10px', 0]}
            maxWidth="600px"
            flexDirection="column"
            justifyContent="center"
            textAlign="center"
            alignItems="start"
            margin="auto"
          >
            <Box
              textAlign={['center', 'left']}
              mt={['5px', 0]}
              marginBottom="auto"
              width={['calc(100% - 10px)', '100%']}
              p={['10px', 0]}
              alignItems={['center', '1']}
              borderBottom={['1px solid #DDE1E6', 'inherit']}
              borderTop={['1px solid #DDE1E6', 'inherit']}
            >
              <Box as="img" pl={3} src={Logo} />
            </Box>
            <H1 mb="60px">Log in to your organization</H1>
            <Flex alignItems="start">
              <H4 mt="18px">https://chemocalc.botmd.io/</H4>
              <Box>
                <Input
                  pr={['10px', 0]}
                  position="absolute"
                  labelProps={{ style: { display: 'none' } }}
                  ml={1}
                  height="48px"
                  maxWidth="235px"
                  value={org.value}
                  state={org.error.length > 0 ? 'danger' : ''}
                  errorLabel={org.error}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === 'Enter') {
                      handleOrgSubmit();
                    }
                  }}
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    setOrg({ value: e.currentTarget.value, error: '' })
                  }
                  placeholder="your organization url"
                />
              </Box>
            </Flex>
            <PrimaryButton
              maxWidth="400px"
              width="100%"
              borderRadius="8px"
              height="48px"
              mt="67px"
              onClick={handleOrgSubmit}
            >
              <Text color="white" fontWeight="600" fonstSize="16px">
                Log In
              </Text>
            </PrimaryButton>
            <Body
              marginTop="auto"
              textAlign="center"
              maxWidth="400px"
              color="#A3A9B1"
              extraSmall
            >
              By registering, you agree to the Terms & Conditions of Use of the
              Service as well as the Bot MD Privacy Policy
            </Body>
          </Flex>
        </Box>
      </Flex>
      <InformationSection />
    </Flex>
  );
}

export default Home;
